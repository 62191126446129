<template>
  <div>
    <base-header class="pb-6">
      <div class="row align-items-center py-4">
        <div class="col-lg-6 col-7">
          <h6 class="h2 text-white d-inline-block mb-0">
            <i class="fa fa-angle-double-up mr-2"></i>{{getTrans('messages.invite_friend')}}</h6>
          <nav class="d-none d-md-inline-block ml-md-4">
            <route-bread-crumb></route-bread-crumb>
          </nav>
        </div>
      </div>
    </base-header>
    <div class="col-12 mt--6 pb-5 bg-pink">
      <card class="mb-2">
        <template v-slot:header>
          <h5 class="h3 mb-0 col-6">{{getTrans('messages.invite_friend')}}</h5>
        </template>
        <div class="row align-items-center">
          <div class="col-8 col-lg-11">
            
          </div>
          <div class="col-4 col-lg-1">
            <el-button type="primary" size="small" @click="tools"
              >{{getTrans('messages.back')}}</el-button
            >
          </div>
        </div>
        <div class="container-fluid mt-3">
          <div class="row align-items-center">
            <div class="col-12">
              <p>{{getTrans('messages.invite_link_description')}}</p>
              <div class="row mb-3">
                <div class="col-12 text-center">
                  <p class="mb-0">
                    {{getTrans('messages.invite_redirect')}}
                    <el-select
                      v-model="redirectToValue"
                      filterable
                      :default-first-option="true"
                    >
                      <el-option
                        v-for="item in invitelinks"
                        :key="item.value"
                        :label="item.label"
                        :value="item.value"
                      >
                      </el-option>
                    </el-select>
                  </p>
                </div>
              </div>
              <div class="row block justify-content-center m-0">
                <div id="inviteAFriendCopy" class="col-12 text-center">
                  <p class="alert alert-default">
                    {{ inviteLink }}
                  </p>
                </div>
                <div>
                  <el-button block type="primary" size="lg" @click="copyLink"
                    >{{getTrans('messages.copy')}}</el-button
                  >
                </div>
              </div>
            </div>
          </div>
          <div class="row block align-items-center mt-2">
            <div class="col-12 col-lg-6">
              <p>{{getTrans('messages.invited_users')}}</p>
            </div>
            <div class="col-12 col-lg-6">
              <p>
                {{getTrans('messages.earnings_sum')}}<span class="mx-2">{{ totalEarnings }}</span
                >{{getTrans('messages.tokens')}}
              </p>
            </div>
          </div>
          <div class="d-flex">
            <el-table
              show-summary=""
              :data="getUser.affiliates"
              border
              style="width: 100%"
            >
              <el-table-column prop="id" label="Id" column-key="id">
              </el-table-column>
              <el-table-column
                prop="subscription_title"
                label="Subscription plan"
              ></el-table-column>
              <el-table-column prop="earnings" label="Earnings">
              </el-table-column>
            </el-table>
          </div>
        </div>
      </card>
    </div>
  </div>
</template>
<script>
// Components
import RouteBreadCrumb from "@/components/Breadcrumb/RouteBreadcrumb";
import BaseHeader from "@/components/BaseHeader";
import {
  ElButton,
  ElSelect,
  ElTable,
  ElTableColumn as ElTableColumn,
  ElOption,
} from "element-plus";
import CopyToClipboard from "@/mixins/CopyToClipboard";
import { mapGetters } from "vuex";
export default {
  name: "invite-a-friend",
  components: {
    BaseHeader,
    RouteBreadCrumb,
    [ElButton.name]: ElButton,
    [ElSelect.name]: ElSelect,
    [ElTable.name]: ElTable,
    [ElTableColumn.name]: ElTableColumn,
    [ElOption.name]: ElOption,
  },
  mixins: [CopyToClipboard],
  data() {
    return {
      invitelinks: [
        {
          value: "",
          label: "Frontpage"
        },
        {
          value: "register",
          label: "Register page"
        },
        {
          value: "model",
          label: "Model register page"
        },
        {
          value: "studio",
          label: "Studio register page"
        },
        {
          value: "affiliate",
          label: "Affiliate register page"
        }
      ],
      redirectToValue: "",
      tableData: [
        {
          id: "Test 14376",
          subscriptionplan: "Test No subscription",
          earnings: "Test 0 Tokens"
        },
        {
          id: "Test 12943",
          subscriptionplan: "Test No subscription",
          earnings: "Test 0 Tokens"
        }
      ]
    };
  },
  created() {
    this.redirectToValue = this.invitelinks[0].value;
  },
  methods: {
    tools() {
      this.$router.push({ name: "Tools" });
    },
    copyLink() {
      this.copyToClipboard("inviteAFriendCopy");
    }
  },
  computed: {
    ...mapGetters("User", ["getUser"]),
    inviteLink() {
      let redirect = this.redirectToValue ? "?to=" + this.redirectToValue : "";
      return (
        process.env.VUE_APP_URL + "invite/" + this.getUser.hashid + redirect
      );
    },
    totalEarnings() {
      let total = 0;
      this.getUser.affiliates.forEach(a => {
        total += a.subscription_amount;
      });
      return total;
    }
  }
};
</script>
<style></style>
